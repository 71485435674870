import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from "../../utils/auth-provider";

import ModalShell from '../common/modal-shell';
import RowShell from '../common/row-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

import CollectionsInfo from '../collection/collections-info';
import CollectionCreateForm from '../collection/collection-create-form';

//================================================================

export default function PublicCollectionsPage(){

  const [collections, setCollections] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const auth = useAuth();
  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchCollections = useCallback(async () => {

    setCollections(await fetch("/api/public/collections")
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `While fetching collections: ${error.message}`
        )]);
      })
    );
    setLoading(false);
  },[toasts]);

  useEffect(() => {
    fetchCollections();
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <CollectionsInfo />

      <hr></hr>

      <Row className="mb-3 align-items-center">
        <Col className="h4 text-start">Collections:</Col>
      </Row>
      {!loading?
        <>
          {collections && collections.length > 0 ?
            <>
              {collections.map(collection => <RowShell
                key={`collection-row-${collection._id}`}
                id={collection._id}
                linkTo={`/collection/${collection._id}`}
                linkText={collection.name}
              />)}
            </>
            :
            <></>
          }
        </>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <Toaster toasts={toasts} />
    </>
  );
}
