import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Form, Button, Spinner } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";

//================================================================

export default function Login() {

  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const [working, setWorking] = useState(false);

  //----------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWorking(true);
    const auth_result = await auth.loginAction(username, password);

    if (!auth_result) {
      setMessage("Invalid Credentials");
    } else {
      setMessage(`Welcome back. Redirecting to your dashboard...`);
      navigate("/dashboard/", { replace: true });
    }
    setTimeout(() => {setMessage("")}, 5000);
    setWorking(false);
  };

  //----------------------------------------------------------------

  return(
    <>
      <Col className="h3">
        Please Log In
      </Col>

      <Form onSubmit={handleSubmit}>
        <Form.Control
          className="mb-2"
          type="text"
          placeholder="username"
          onChange={e => setUsername(e.target.value)}
          disabled={working}
        />

        <Form.Control
          className="mb-2"
          type="password"
          placeholder="password"
          onChange={e => setPassword(e.target.value)}
          disabled={working}
        />

        <Button
          className="w-100"
          type="submit"
          disabled={working}
        >
          {working ? <>
            <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
          />{' '}
          Working...
          </> : "Submit"}
        </Button>
      </Form>

      <Col className="text-center">
        {message}
      </Col>
    </>
  )
}
