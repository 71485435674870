import { Card, Button, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth-provider";

//================================================================

export default function Home() {

  const user = useAuth();

  return (
    <Card className="border-0">
      <Card.Body className="m-0 p-0">

        <Card.Title>
          <div className="mb-4">
            Collecting and Processing Environmental Plastics
          </div>
        </Card.Title>

        <Col className="mb-3">
          <div className="mb-2">
            This application is designed to be an aid in collecting and cataloging plastic waste from the environment.
          </div>
          <div className="mb-2">
            It started with an idea to get back down to earth, specifically the earth beneath my very feet. I'm frequently out on my bike, along roads and waterways, through parks and neighborhoods. The amount of human litter is astounding, and much of it is plastic, destined to find its way into the geological record.
          </div>
          <div className="mb-2">
            I want to keep detailed records of the Forays I venture out on and the plastic Items I encounter. Many will be no condition to recycle, some may even be too hazardous to collect. Perhaps the images and geocoordinates logged along with other details will be useful for the future. I have some ideas in mind as to what to do with all this data and will publish documentation of those developments here as well.
          </div>
        </Col>

        <Col className="text-center">
          {user.token ?
            <Link to="dashboard">
              <Button>
                View Your Dashboard
              </Button>
            </Link>
            :
            <Link to="login">
              <Button>
                Sign in
              </Button>
            </Link>
          }
        </Col>

      </Card.Body>
    </Card>
  );
}
