import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import ParentDropdown from '../common/parent-dropdown';
import RowShell from '../common/row-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import { useAuth } from "../../utils/auth-provider";

import CollectionDetails from './collection-details';
import CollectionUpdateForm from './collection-update-form';
import CollectionCreateForm from './collection-create-form';
import ItemCreateForm from '../item/item-create-form';
import ImageCreateForm from '../image/image-create-form';
import AddChildren from '../common/add-children';

//================================================================

export default function CollectionPage(){

  //----------------------------------------------------------------
  //Hooks and Variables

  const user = useAuth();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  };

  const params = useParams();

  const [parents, setParents] = useState(null);

  const [collection, setCollection] = useState(null);
  const [collections, setCollections] = useState([]);
  const [createCollectionModalShow, setCreateCollectionModalShow] = useState(false);
  const [addCollectionModalShow, setAddCollectionModalShow] = useState(false);

  const [items, setItems] = useState([]);
  const [createItemModalShow, setCreateItemModalShow] = useState(false);
  const [addItemModalShow, setAddItemModalShow] = useState(false);

  const [images, setImages] = useState([]);
  const [createImageModalShow, setCreateImageModalShow] = useState(false);
  const [addImageModalShow, setAddImageModalShow] = useState(false);

  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [children, setChildren] = useState(null);
  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------
  // Fetching and setting Collections

  const fetchCollections = useCallback( async () => {
    await setCollections( await fetch("/api/collection/", {headers: headers})
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch collections: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------
  // Fetch Items

  const fetchItems = useCallback( async () => {
    setItems( await fetch("/api/item/", {headers: headers})
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch items: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------
  // Fetching Images

  const fetchImages = useCallback( async () => {
    await setImages( await fetch("/api/image/", {headers: headers})
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch images: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------
  // Select the current Collection by ID

  const setCurrentCollection = useCallback( async (collectionId) => {
    if (!collectionId) return;
    const index = await collections.findIndex(candidate => candidate._id === collectionId);
    if (index >= 0){
      collections[index].index = index;
      setCollection(collections[index]);
    }
  });

  useEffect(() => {
    setCurrentCollection(params.collectionId);
  }, [collections]);

  //----------------------------------------------------------------

  const addChildren = useCallback(async (children) => {
    if (!collection) return;

    await fetch(`/api/collection/${collection._id}`, {
      method: 'PUT',
      body: JSON.stringify({'add_children': children}),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok){
        setCollection(result);
        setAddItemModalShow(false);
        setCreateItemModalShow(false);
      } else {
        setToasts([...toasts, createToast(
          "Failed to add children to collection",
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding children ${children} to collection ${collection._id}: ${error.message}`
      )]);
    });
  }, [collection, toasts]);

  //----------------------------------------------------------------

  const removeChild = useCallback(async (childId) => {
    await fetch(`/api/collection/${collection._id}`, {
      method: 'PUT',
      body: JSON.stringify({'remove_children' : [childId]}),
      headers: headers
    })
    .then(async (response) => {
      if(response.ok){
        const result = await response.json();
        setCollection(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to remove item from collection",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While removing item ${childId} from collection ${collection._id}: ${error.message}`
      )]);
    });
  }, [children, toasts, collection]);

  //----------------------------------------------------------------

  const fetchChildren = useCallback(async () => {
    if (!collection) return;

    await setChildren(await fetch(`/api/collection/${collection._id}/children`, {headers: headers})
    .then((response) => {
      if (response.ok) return response.json();
      return {'items': null, 'collections': null, 'images': null};
    }).catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching children for collection ${collection._id}: ${error.message}`
      )]);
    }));
  }, [collection, toasts]);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async () => {
    if(!collection) return;
    setParents(await fetch(`/api/collection/${collection._id}/parents`, {headers: headers})
      .then((response) => {
        if (response.ok) return response.json();
        return null;
      })
    );
  });

  //----------------------------------------------------------------

  useEffect(() => {
    setLoading(true);
    fetchChildren();
    fetchParents();
    setLoading(false);
  }, [collection]);

  useEffect(() => {
    fetchCollections();
    fetchItems();
    fetchImages();
  }, []);

  return(
    <>
      <PreviousNext
        objects={collections}
        currentObject={collection}
        onClick={setCurrentCollection}
        basePath={'/dashboard/collection'}
      />

      <Row className="mb-2">
        <Col className="h4">Collection:</Col>
      </Row>

      {loading ?
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
        :
        <>
          {collection ?
            <CollectionDetails collection={collection}/>
            :
            <></>
          }
        </>
      }

      <ParentDropdown options={parents} baseURL="/dashboard"/>

      <Row className="mb-2 align-children-center">
        <Col className="text-start">
          <Link to={'/dashboard/collections/'}>
            <Button className="text-nowrap">
              Back to Collections
            </Button>
          </Link>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => setUpdateModalShow(true)}
            disabled={!collection}
          >
            Edit
          </Button>
        </Col>
      </Row>

      <hr></hr>

      {!loading ?
        <>
          <Row className="mb-3 align-children-center">
            <Col className="h4 my-0 text-start">Children:</Col>
          </Row>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Collections:</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col col-md-3 text-center">
              <Button
                onClick={() => setCreateCollectionModalShow(true)}
              >
                New
              </Button>
              <Button
                onClick={() => setAddCollectionModalShow(true)}
              >
                Existing
              </Button>
            </ButtonGroup>
          </Row>

          <div className="mb-3">
            {children && children['collections'] ?
              <>
                {children['collections'].map(collection => <RowShell
                  key={`collection-row-${collection._id}`}
                  id={collection._id}
                  linkTo={`/dashboard/collection/${collection._id}`}
                  linkText={collection.name}
                  onAction={removeChild}
                  icon='dash'
                  rounded='1'
                />)}
              </>
              :
              <></>
            }
          </div>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Items:</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col col-md-3 text-center">
              <Button
                onClick={() => setCreateItemModalShow(true)}
              >
                New
              </Button>
              <Button
                onClick={() => setAddItemModalShow(true)}
              >
                Existing
              </Button>
            </ButtonGroup>
          </Row>


          <div className="mb-3">
            {children && children['items'] ?
              <>
                {children['items'].map(item => <RowShell
                  key={`item-row-${item._id}`}
                  id={item._id}
                  linkTo={`/dashboard/item/${item._id}`}
                  linkText={item.name}
                  onAction={removeChild}
                  icon='dash'
                  rounded='1'
                />)}
              </>
              :
              <></>
            }
          </div>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Images:</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col col-md-3 text-center">
              <Button
                onClick={() => setCreateImageModalShow(true)}
              >
                New
              </Button>
              <Button
                onClick={() => setAddImageModalShow(true)}
              >
                Existing
              </Button>
            </ButtonGroup>
          </Row>

          <div className="mb-3">
            {children && children['images'] ?
              <>
                {children['images'].map(image => <RowShell
                  key={`image-row-${image._id}`}
                  id={image._id}
                  linkTo={`/dashboard/image/${image._id}`}
                  linkText={image.name}
                  onAction={removeChild}
                  icon='dash'
                  rounded='1'
                />)}
              </>
              :
              <></>
            }
          </div>
        </>

        :

        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>

      }

      <ModalShell
        key="createCollectionModal"
        title="Add New Collection"
        show={createCollectionModalShow}
        onHide={() => setCreateCollectionModalShow(false)}
        hideFooter={true}
      >
        <CollectionCreateForm
          onCancel={() => setCreateCollectionModalShow(false)}
          onCreate={(child) => {
            addChildren([child._id]);
            setCreateCollectionModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        key="addCollectionModal"
        title="Add Existing Collection(s)"
        show={addCollectionModalShow}
        onHide={() => setAddCollectionModalShow(false)}
        hideFooter={true}
      >
        <AddChildren
          parent={collection}
          childList={collections && collection ? collections.filter(child => child._id !== collection._id && !collection.children.includes(child._id)) : []}
          onAdd={(children) => {
            addChildren(children);
            setAddCollectionModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        key="createItemModal"
        title="Add New Item"
        show={createItemModalShow}
        onHide={() => setCreateItemModalShow(false)}
        hideFooter={true}
      >
        <ItemCreateForm
          onCancel={() => setCreateItemModalShow(false)}
          onCreate={(child) => {
            addChildren([child._id]);
            setCreateItemModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        key="addItemModal"
        title="Add Existing Items(s)"
        show={addItemModalShow}
        onHide={() => setAddItemModalShow(false)}
        hideFooter={true}
      >
        <AddChildren
          parent={collection}
          childList={items && collection ? items.filter(item => !collection.children.includes(item._id)) : []}
          onAdd={(childList) => {
            addChildren(childList);
            setAddItemModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        key="createImageModal"
        title="Add New Image"
        show={createImageModalShow}
        onHide={() => setCreateImageModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setCreateImageModalShow(false)}
          onCreate={(child) => {
            addChildren([child._id]);
            setCreateImageModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        key="addImageModal"
        title="Add Existing Images(s)"
        show={addImageModalShow}
        onHide={() => setAddImageModalShow(false)}
        hideFooter={true}
      >
        <AddChildren
          parent={collection}
          childList={images && collection ? images.filter(image => !collection.children.includes(image._id)) : []}
          onAdd={(childList) => {
            addChildren(childList);
            setAddImageModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        key="updateModal"
        title="Update Collection"
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        hideFooter={true}
      >
        <CollectionUpdateForm
          collection={collection}
          onCancel={() => setUpdateModalShow(false)}
          onUpdate={fetchCollections}
        />
      </ModalShell>

      <Toaster toasts={toasts} />

    </>
  );
}
