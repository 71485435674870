import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import CardShell from '../common/card-shell';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';

//================================================================

export default function ImagesPage(){

  const [images, setImages] = useState([]);
  const [toasts, setToasts] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchImages = useCallback( async () => {
    setImages(await fetch(`/api/public/images`)
      .then(response => response.json())
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch images: ${error.message}`
        )]);
      })
    );
    setLoading(false);
  },[toasts]);

  useEffect(() => {
    fetchImages();
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <Row className="mb-3 align-items-center">
        <Col className="h4 my-0 text-start">Images:</Col>
      </Row>

      {!loading ?
        <>
          { images && images.length > 0 ?
            <>
              {images.map(image => <RowShell
                key={`image-row-${image._id}`}
                id={image._id}
                linkTo={`/image/${image._id}`}
                linkText={image.name}
              />)}

              <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4 mb-2 p-0">
                {images.map(image => <CardShell
                  key={`image-card-${image._id}`}
                  id={image._id}
                  imgSrc={`/api/public/image/${image._id}/file?width=250&height=250`}
                  linkTo={`/image/${image._id}`}
                  title={image.name}
                />)}
              </Row>
            </>
            :
            <></>
          }
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

      <Toaster toasts={toasts} />
    </>
  );
}
