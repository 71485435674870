import { Link } from 'react-router-dom';
import { Col, Row, Button, Spinner, ToggleButton, Form } from 'react-bootstrap';
import { useState, useCallback } from 'react';

import RemoveButton from './remove-button'

//================================================================

export default function RowShell({linkTo, linkText, id, onAction, onSelect, icon, rounded}){

  return(
    <Row className="mb-1 align-items-center border border-1 rounded py-2">
      { onSelect !== undefined ?
        <Col className="col-1">
          <Form.Check
            type="checkbox"
            id={`select-${id}`}
            onChange={(event) => {onSelect(event.target.checked, id)}}
          />
        </Col>
        :
        <></>
      }
      <Col className="text-start text-truncate">
        {linkTo ?
          <Link
            reloadDocument
            to={linkTo}
            onClick={() => console.log(`should navigate to: ${linkTo}`)}
          >
            {linkText}
          </Link>
          :
          <>{linkText}</>
        }
      </Col>
      { onAction !== undefined ?
        <Col className="text-end">
          <RemoveButton
            key={`remove-button-row-${id}`}
            objectId={id}
            id={`row-${id}`}
            onAction={onAction}
            icon={icon}
            rounded={rounded}
          />
        </Col>
        :
        <></>
      }
    </Row>
  );
}
