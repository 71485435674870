import { Card, Button, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth-provider";
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../components/common/modal-shell';
import RowShell from '../components/common/row-shell';
import BasicSpinner from '../components/common/basic-spinner';
import { Toaster, createToast } from '../components/common/toasts';

import CollectionCreateForm from '../components/collection/collection-create-form';
import ItemCreateForm from '../components/item/item-create-form';
import ImageCreateForm from '../components/image/image-create-form';


//================================================================

export default function Dashboard() {

  const user = useAuth();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  };

  const [collections, setCollections] = useState([]);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [toasts, setToasts] = useState([]);
  const [createCollectionModalShow, setCreateCollectionModalShow] = useState(false);
  const [createItemModalShow, setCreateItemModalShow] = useState(false);
  const [createImageModalShow, setCreateImageModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchCollections = useCallback(async () => {
    setCollections(await fetch(`/api/collection/`, {headers: headers})
    .then((response) => {
      if (response.ok) return response.json();
      setToasts([...toasts, createToast(
        "Failed to fetch collections",
        `${response.status} - ${response.json()}`
      )]);
      return [];
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching collections: ${error.message}`
      )]);
    }));
  }, [toasts]);

  //----------------------------------------------------------------

  const fetchItems = useCallback(async () => {
    setItems(await fetch(`/api/item/`, {headers: headers})
    .then((response) => {
      if (response.ok) return response.json();
      setToasts([...toasts, createToast(
        "Failed to fetch items",
        `${response.status} - ${response.json()}`
      )]);
      return [];
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching items: ${error.message}`
      )]);
    }));
  }, [toasts]);

  //----------------------------------------------------------------

  const fetchImages = useCallback(async () => {
    setImages(await fetch(`/api/image/`, {headers: headers})
    .then(response => {
      if (response.ok) return response.json();
      setToasts([...toasts, createToast(
        "Failed to fetch images",
        `${response.status} - ${response.json()}`
      )]);
      return [];
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching images: ${error.message}`
      )]);
    }));
  },[toasts]);

  //----------------------------------------------------------------

  useEffect(() => {
    setLoading(true);
    fetchCollections();
    fetchItems();
    fetchImages();
    setLoading(false);
  }, [user]);

  //----------------------------------------------------------------

  return (
    <>
      {loading ?
        <>
          <Row className="mb-3">
            <Col className="text-center">
              <BasicSpinner text={'Loading...'} />
            </Col>
          </Row>
        </>

        :

        <>
          <Row className="mb-2 align-children-center">
            <Col className="h5 my-0 text-start">
              <Link
                to="/dashboard/collections/"
                className="link-dark link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                Collections:
              </Link>
            </Col>
            <Col className="text-end">
              <Button
                className="text-nowrap btn-sm"
                onClick={() => setCreateCollectionModalShow(true)}
              >
                Add A Collection
              </Button>
            </Col>
          </Row>

          { collections ?
            <Col className="mb-4">
              {collections.map(collection => <RowShell
                key={`collection-link-${collection._id}`}
                id={collection._id}
                linkTo={`/dashboard/collection/${collection._id}`}
                linkText={collection.name}
              />)}
            </Col>
            :
            <Row className="mb-3 align-children-center">
              <Col className="my-0 text-center">You haven't made any collections.</Col>
            </Row>
          }

          <Row className="mb-2 align-children-center">
            <Col className="h5 my-0 text-start">
              <Link
                to="/dashboard/items/"
                className="link-dark link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                Items:
              </Link>
              </Col>
            <Col className="text-end">
              <Button
                className="text-nowrap btn-sm"
                onClick={() => setCreateItemModalShow(true)}
              >
                Add An Item
              </Button>
            </Col>
          </Row>

          { items ?
            <Col className="mb-4">
              {items.map(item => <RowShell
                key={`item-link-${item._id}`}
                id={item._id}
                linkTo={`/dashboard/item/${item._id}`}
                linkText={item.name}
              />)}
            </Col>
            :
            <Row className="mb-3 align-children-center">
              <Col className="my-0 text-center">You haven't documented and items.</Col>
            </Row>
          }

          <Row className="mb-2 align-children-center">
            <Col className="h5 my-0 text-start">
              <Link
                to="/dashboard/images/"
                className="link-dark link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                Images:
              </Link>
            </Col>
            <Col className="text-end">
              <Button
                className="text-nowrap btn-sm"
                onClick={() => setCreateImageModalShow(true)}
              >
                Add An Image
              </Button>
            </Col>
          </Row>

          { images ?
            <Col className="mb-4">
              {images.map(image => <RowShell
                key={`image-link-${image._id}`}
                id={image._id}
                linkTo={`/dashboard/image/${image._id}`}
                linkText={image.name}
              />)}
            </Col>
            :
            <Row className="mb-3 align-children-center">
              <Col className="my-0 text-center">You haven't uploaded any images.</Col>
            </Row>
          }
        </>
      }
    </>
  );
}
