import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from "../../utils/auth-provider";

import ModalShell from '../common/modal-shell';
import RowShell from '../common/row-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

import CollectionsInfo from './collections-info';
import CollectionCreateForm from './collection-create-form';

//================================================================

export default function CollectionsPage(){

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const [collections, setCollections] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const auth = useAuth();
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const deleteCollection = useCallback(async (collectionId) => {
    await fetch(`/api/collection/${collectionId}`, {
      method: 'DELETE',
      headers: headers
    })
    .then((response) => {
      if(response.status === 204 || response.status === 404){
        const filter = collections.filter(collection => collection._id !== collectionId);
        setCollections(filter);
      } else {
        setToasts([...toasts, createToast(
          "Failed to delete collection",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While deleting collection ${collectionId}: ${error.message}`
      )]);
    });
  }, [collections]);

  //----------------------------------------------------------------

  const addCollection = useCallback((collection) => {
    setCollections([...collections, collection]);
    setModalShow(false);
  }, [collections]);

  //----------------------------------------------------------------

  const fetchCollections = useCallback(async () => {

    setCollections(await fetch("/api/collection/",{ headers: headers })
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `While fetching collections: ${error.message}`
        )]);
      })
    );
  });

  useEffect(() => {
    fetchCollections();
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <CollectionsInfo />

      <hr></hr>

      <Row className="mb-3 align-items-center">
        <Col className="h4 text-start">Collections:</Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            onClick={() => setModalShow(true)}
          >
            New Collection
          </Button>
        </Col>
      </Row>

      {collections !== null ?
        <>
          {collections.map(collection => <RowShell
            key={`collection-row-${collection._id}`}
            id={collection._id}
            linkTo={`/dashboard/collection/${collection._id}`}
            linkText={collection.name}
            onAction={deleteCollection}
            icon='trash'
            rounded='1'
          />)}
        </>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <ModalShell
        title="Start New Collection"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <CollectionCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addCollection}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}
