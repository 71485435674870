import { useParams, Link } from "react-router-dom";

import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import CardShell from '../common/card-shell';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';

//================================================================

export default function ItemsPage(){
  const params = useParams();
  const [items, setItems] = useState(null);
  const [toasts, setToasts] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchItems = useCallback( async () => {

    setItems(await fetch(`/api/public/items`)
      .then((response) => {
        if (response.ok) return response.json();
        return null;
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch items: ${error.message}`
        )]);
      })
    );
    setLoading(false);
  },[toasts]);

  useEffect(() => {
    fetchItems();
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <Row className="mb-3 align-items-center">
        <Col className="h4 my-0 text-start">Items:</Col>
      </Row>
      {!loading ?
        <>
          {items && items.length > 0 ?
            <>
              {items.map(item => <RowShell
                key={`item-row-${item._id}`}
                id={item._id}
                linkTo={`/item/${item._id}`}
                linkText={item.name}
              />)}

              <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4 mb-2 p-0">
                {items.map(item => <CardShell
                  key={`item-card-${item._id}`}
                  id={item._id}
                  imgSrc={item.images[0] ? `/api/public/image/${item.images[0]}/file?width=250&height=250` : null}
                  linkTo={`/item/${item._id}`}
                  title={item.name}
                />)}
              </Row>
            </>
            :
            <></>
          }
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

      <Toaster toasts={toasts} />
    </>
  );
}
