import { Container, Nav, Navbar, Row, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from "../../utils/auth-provider";

export default function HomeNav(){

  const user = useAuth();

  return(
    <Navbar expand='false' sticky="top" className="bg-body-tertiary mb-3">
      <Container className="px-4" fluid>
        <Navbar.Brand className="col">
          <Row className="my-2">
            <div className="fs-3 fw-light">
              The Plasticene
            </div>
            <div className="fs-6 fw-light">
              A project by <Link to="https://phillipstearns.com" target="_blank">Phillip David Stearns</Link>
            </div>
          </Row>
        </Navbar.Brand>
        <Navbar.Toggle className="rounded border-0" aria-controls={`offcanvasNavbar-expand-home`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-home`}
          aria-labelledby={`offcanvasNavbarLabel-expand-home`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-home`}>
              Navigation
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              <hr/>
              <Nav.Link href="/collections/">Collections</Nav.Link>
              <Nav.Link href="/items/">Items</Nav.Link>
              <Nav.Link href="/images/">Images</Nav.Link>
              <hr/>
              {user.user ?
                <>
                  <Nav.Link
                    onClick={() => user.logOut()}
                  >
                    Sign Out
                  </Nav.Link>
                </>
                :
                <Nav.Link href="/login/">Sign In</Nav.Link>
              }
      
            </Nav>
           
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
