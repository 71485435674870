import { Row, Col, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default function ParentDropdown({options, baseURL}) {

  const basePath = baseURL || "";

  const [disabled, setDisabled] = useState(true);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (url) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [options, url]);

  return (
    <Row className="mb-2 align-items-center">
      <Col className="col-3">
        <Button
          href={url}
          disabled={disabled}
          className="text-nowrap w-100"
        >
          Go to parent:
        </Button>
      </Col>
      <Col className="col-9">
        <Form.Select
          aria-label="Parent Select Dropdown"
          onChange={(event) => {
            event.target.value === '-1' ? setUrl(null) : setUrl(event.target.value);
          }}
        >
          { options ?
            <>
              <option value='-1'>
                Select parent
              </option>
              {options.items.map((option) => <option
                key={`option-${option._id}`}
                value={`${basePath}/item/${option._id}`}>
                  {option.name}
                </option>)}
              {options.collections.map((option) => <option
                key={`option-${option._id}`}
                value={`${basePath}/collection/${option._id}`}>
                  {option.name}
                </option>)}
            </>
            :
            <option>
              No Parents
            </option>
          }
        </Form.Select>
      </Col>
    </Row>
  );
}
