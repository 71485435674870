import { useParams, Link } from "react-router-dom";

import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ParentDropdown from '../common/parent-dropdown';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';

import ImageDetails from '../image/image-details';

//================================================================

export default function ImagePage(){
  const params = useParams();

  const [parents, setParents] = useState(null);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(true);

  //----------------------------------------------------------------

  const fetchImages = useCallback( async () => {
    setImages(await fetch(`/api/public/images`)
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch images: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  useEffect(() => {
    fetchImages();
  }, []);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async () => {
    if(!image) return;
    setParents( await fetch(`/api/public/image/${image._id}/parents`)
      .then((response) => {
        if (response.ok) return response.json();
        return null;
      })
    );
    setLoading(false);
  });

  useEffect(() => {
    fetchParents();
  }, [image]);

  //----------------------------------------------------------------

  const setCurrentImage = useCallback( async (imageId) => {
    setLoading(true);
    if (!imageId) return;
    const index = await images.findIndex((candidate) => candidate._id === imageId);
    if (index >= 0){
      images[index].index = index;
      setImage(images[index]);
    }
  }, [images]);

 //----------------------------------------------------------------

  useEffect(() => {
    setCurrentImage(params.imageId);
  }, [images, params.imageId]);

  return(
    <>
      <PreviousNext
        objects={images}
        currentObject={image}
        onClick={setCurrentImage}
        basePath={`/image`}
      />

      {!loading ?
        <>
          {image ?
            <ImageDetails image={image}/>
            :
            <>Unable to load Image</>
          }
        </>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <ParentDropdown options={parents} />

      <Row className="mb-2 align-items-center">
        <Col className="text-center mb-2">
          <Link to={`/images/`}>
            <Button className="w-100 text-nowrap">
              Back to Images
            </Button>
          </Link>
        </Col>
      </Row>

      <Toaster toasts={toasts} />
    </>
  );
}
