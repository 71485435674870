import { Col, Row } from 'react-bootstrap';

//================================================================

export default function CollectionDetails({collection}){

  const createdOn = new Date(collection.created * 1000).toLocaleString() || "unavailable";
  const modifiedOn = new Date(collection.modified * 1000).toLocaleString() || "unavailable";

  //----------------------------------------------------------------

  return(
    <div className="mb-4">
      <Row>
        <Col className="text-truncate">
          Name: {collection.name}
        </Col>
      </Row>
      {/*<Row>
        <Col className="text-truncate">
          ID: {collection._id}
        </Col>
      </Row>*/}
      <Row>
        <Col>
        Description: {collection.description}
        </Col>
      </Row>
      <Row>
        <Col>
        Tags: {collection.tags.join(", ")}
        </Col>
      </Row>
      <Row>
        <Col>
        Created On: {createdOn}
        </Col>
      </Row>
     {/* <Row>
        <Col>
        Last Updated: {modifiedOn}
        </Col>
      </Row>*/}
      <Row>
        <Col>
        Type: {collection.type}
        </Col>
      </Row>
      <Row>
        <Col>
        Private: {collection.is_private}
        </Col>
      </Row>
    </div>
  );
}
