import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { ImageTypes } from '../../enums';

import BasicSpinner from '../common/basic-spinner';
import UpdateFormTags from '../common/update-form-tags';
import { Toaster, createToast } from '../common/toasts';
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function ImageUpdateForm({image, onUpdate, onCancel}){

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const [name, setName] = useState(image.name);
  const [description, setDescription] = useState(image.description);
  const [working, setWorking] = useState(false);
  const [imageType, setImageType] = useState(ImageTypes.UNSET);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const sendUpdateRequest = useCallback(async (data, imageId) => {
    if (Object.keys(data).length <= 0) return null;

    setWorking(true);
    const updatedImage = await fetch(`/api/image/${imageId}`, {
      method : 'PUT',
      body : JSON.stringify(data),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok){
        onUpdate(result);
        return result;
      }
      setToasts([...toasts, createToast(
        "Failed to update image",
        `${response.status} - ${response.json()}`
      )]);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to update image ${imageId}: ${error.message}`
      )]);
    });
    setWorking(false);
    return updatedImage;
  }, [onUpdate]);

  const submitHandler = useCallback(async () => {
    const data = {};

    if (name !== image.name){
      data['name']=name;
    }

    if (description !== image.description){
      data['description'] = description;
    }

    if(imageType !== image.image_type){
     data['image_type'] = imageType;
    }

    if (Object.keys(data).length > 0){
      await sendUpdateRequest(data, image._id);
    }
    onCancel();
  }, [name, description, imageType, image, onCancel]);

  //----------------------------------------------------------------

  return(
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Image a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Image Description</Form.Label>
        <Form.Control
          as="textarea"
          value={description}
          placeholder="Enter description"
          onChange={(event) => setDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of the image.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Image Type</Form.Label>
        <Form.Select
          onChange={(event) => setImageType(event.target.value)}
          disabled={working}
          defaultValue={imageType}
        >
          {Object.keys(ImageTypes).map(key =>
            <option
              key={`imageType-option-${key}`}
              value={ImageTypes[key]}
            >
              {ImageTypes[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <UpdateFormTags
        object={image}
        onSubmit={sendUpdateRequest}
        disabled={working}
      />

      <Row className="align-images-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={submitHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Saving Changes..." />
              :
              "Save Changes"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
