import { Card, Col } from 'react-bootstrap';

//================================================================

export default function CollectionsInfo() {
  return (
    <Card className="border-0">
      <Card.Body className="m-0 p-0">
        <Card.Title>
          Collections
        </Card.Title>
        <div className="mb-2">
          A way of grouping, organizing different items. They come in a few different flavors:
        </div>
        <ul>
          <li>BASE: Top level. Can be nested within other BASE collections and have individual Items and Collections of any other type as a child.</li>
          <li>FORAY: Can have SITE collection types and Items as children.</li>
          <li>SITE: Can only have Items as children.</li>
          <li>ARTIFACT: Can only have Items as children.</li>
        </ul>
      </Card.Body>
    </Card>
  );
}
