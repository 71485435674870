import { Outlet, Link } from 'react-router-dom';
import { Container, Breadcrumb, Row } from 'react-bootstrap';
import { useAuth } from "../utils/auth-provider";
import HomeNav from '../components/nav-bars/home-nav'

//================================================================

export default function HomeLayout(){

  const user = useAuth();

  return (
    <>
      <HomeNav />
      <Container>
        <Outlet />

        <hr className="mb-3" />

        <Row>
          <Breadcrumb as="nav" className="d-flex justify-content-center">
            <Link to="/">
              Home
            </Link>&nbsp;|&nbsp;
            {user.token ?
              <>
                <Link to="/dashboard/">
                  Dashboard
                </Link>&nbsp;|&nbsp;
                <Link onClick={() => user.logOut()}>
                  Sign out
                </Link>
              </>
              :
              <Link to="/login">
                Sign in
              </Link>
            }
          </Breadcrumb>
        </Row>

      </Container>
    </>
  )
};
