import { Image, Col, Row} from 'react-bootstrap';
import { useState } from 'react';
import BasicSpinner from '../common/basic-spinner';

//================================================================

export default function ImageDetails({image}){

  const [loading, setLoading] = useState(true);
  const createOn = new Date(image.created * 1000).toLocaleString() || "unavailable";
  const modifiedOn = new Date(image.modified * 1000).toLocaleString() || "unavailable";
  const name = image.name || "unnamed";
  const filename = image.filename || "unset";
  const description = image.description || "Image has no description.";
  const url = `/static/uploads/${image.owner_id}/${image.sha256}.${image.format.split('/')[1]}`
  const tags = image.tags.join(", ") || "Image has no tags";
  const size = image.size || "unset";
  const format = image.format || "unset";
  const imageType = image.image_type || "unset";
  const sha256 = image.sha256 || "unset";

  //----------------------------------------------------------------

  return(
    <div className="mb-4">
      <Row>
        <Col className="h4 text-truncate mb-2">
          {name}
        </Col>
      </Row>
      <Row className="position-relative mb-2">
        <Col
          className="position-absolute h-100"
          hidden={!loading}
          style={{zIndex: 2}}
        >
          <BasicSpinner
            className="position-absolute top-50 start-50 translate-middle"
            text="Image loading..."
          />
        </Col>
        <Col
          className="position-absolute h-100 bg-light opacity-75"
          hidden={!loading}
          style={{zIndex: 1}}
        />
        <Col>
          <Image src={url}
            fluid
            onLoad={() => setLoading(false)}
            style={{zIndex: 0}}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          ID: {image._id}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Uploaded Filename: {filename}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          SHA256: {sha256}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Format: {format}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Size (bytes): {size}
        </Col>
      </Row>
      <Row>
        <Col>
          Description: {description}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Type: {imageType}
        </Col>
      </Row>
      <Row>
        <Col>
          Tags: {tags}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Added On: {createOn}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Last Updated: {modifiedOn}
        </Col>
      </Row>
    </div>
  );
}
