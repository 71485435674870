import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ParentDropdown from '../common/parent-dropdown';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';

import ItemDetails from '../item/item-details';

//================================================================

export default function ItemPage(){

  const params = useParams();

  const [parents, setParents] = useState(null);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();

  const [itemImages, setItemImages] = useState(null);
  const [loading, setLoading] = useState(true);

  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const fetchItems = useCallback(async () => {

    setItems(await fetch(`/api/public/items`)
    .then((response) => {
      if (response.ok) return response.json();
      setToasts([...toasts, createToast(
        "Failed to fetch items",
        `${response.status} - ${response.json()}`
      )]);
      return [];
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching items: ${error.message}`
      )]);
    }));
  }, [params.itemId, toasts]);

  useEffect(() => {
    fetchItems();
  }, []);

  //----------------------------------------------------------------

  const setCurrentItem = useCallback(async (itemId) => {
    setLoading(true);
    const index = await items.findIndex((element) => element._id === itemId);
    if (index >= 0){
      items[index].index = index;
      setItem(items[index]);
    }
  }, [items]);

  useEffect(() => {
    setCurrentItem(params.itemId);
  }, [items]);

  //----------------------------------------------------------------

  const fetchItemImages = useCallback(async (item) => {
    if (!item) return;

    setItemImages(await fetch(`/api/public/item/${item._id}/images`)
    .then(async (response) => {
      if (response.ok){
        const {images} = await response.json();
        return images;
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to fetch images for item ${item._id}: ${error.message}`
      )]);
    }));
    setLoading(false);

  },[item]);

  useEffect(() => {
    fetchParents();
    fetchItemImages(item);
  }, [item]);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async () => {
    if(!item) return;
    setParents( await fetch(`/api/public/item/${item._id}/parents`)
      .then((response) => {
        if (response.ok) return response.json();
        return null;
      })
    );
  });

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={items}
        currentObject={item}
        onClick={setCurrentItem}
        basePath={`/item`}
      />

      <Row className="mb-2">
        <Col className="h4">Item:</Col>
      </Row>

      {!loading ?
        <>
          {item ?
            <ItemDetails item={item}/>
            :
            <>Unable to load Item</>
          }
        </>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

      <ParentDropdown options={parents} />

      <Row className="mb-2 align-items-center">
        <Col className="text-start">
          <Link to={`/items/`}>
            <Button className="text-nowrap">
              Back to Items
            </Button>
          </Link>
        </Col>
      </Row>

      <hr></hr>

      <Row className="mb-3 align-children-center">
        <Col className="h5 m-auto text-start col">Images:</Col>
      </Row>

      {!loading ?
        <>
          {itemImages ?
            <>
              {itemImages.map(image => <RowShell
                key={`image-link-${image._id}`}
                id={image._id}
                linkTo={`/image/${image._id}`}
                linkText={image.name}
              />)}
            </>
            :
            <></>
          }
        </>
        :
        <>
          <Row className="mb-3 align-children-center">
            <Col className="m-auto text-center">
              <BasicSpinner text={'Loading...'}/>
            </Col>
          </Row>
        </>
      }

      <Toaster toasts={toasts} />
    </>
  );
}
