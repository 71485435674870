import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback} from 'react';

import { formatTagsJSON } from '../../utils/utils';
import { CollectionTypes } from '../../enums';
import { useAuth } from "../../utils/auth-provider";
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function CollectionCreateForm({onCreate, onCancel}) {

  const user = useAuth();

  //----------------------------------------------------------------
  // Hooks
  const namePlaceholder = `New Collection: ${new Date().toLocaleString()}`;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTagsString] = useState("");
  const [type, setType] = useState(CollectionTypes.BASE)
  const [children, setChildren] = useState([]);
  const [possibleChildren, setPossibleChildren] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [possibleCollaborators, setPossibleCollaborators] = useState([]);
  const [isPrivate, setIsPrivate] = useState(true);
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------
  // Functions

  const createCollectionHandler = useCallback(async () => {
    setWorking(true);

    const data = {
      'name': name || namePlaceholder,
      'description': description,
      'tags': formatTagsJSON(tags),
      'collaborators': collaborators,
      'children': children,
      'is_private': isPrivate,
      'type': type
    }

    await fetch('/api/collection/', {
      method : 'POST',
      body : JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.token}`
      }
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        onCreate(result);
      } else {
      setToasts([...toasts, createToast(
          `Failed to create new collection`,
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to create new collection: ${error.message}`
      )]);
    })

    setWorking(false);
  }, [name, namePlaceholder, description, tags, onCreate]);

  //----------------------------------------------------------------

  return (
    <Form className="mb-4">

      {/* NAME */}

      <Form.Group className="mb-4">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          placeholder={namePlaceholder}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted" />
      </Form.Group>

      {/* DESCRIPTION */}

      <Form.Group className="mb-4">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter descrition"
          onChange={(event) => setDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted" />
      </Form.Group>

      {/* TAGS */}

      <Form.Group className="mb-4">
        <Form.Label>Tags</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter tags"
          onChange={(event) => setTagsString(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Single-word tags separated by spaces, please.
        </Form.Text>
      </Form.Group>

      {/* TYPE */}

      <Form.Group className="mb-4">
        <Form.Label>Collection Type</Form.Label>
        <Form.Select
          onChange={(event) => setType(event.target.value)}
          disabled={working}
          defaultValue={type}
        >
          {Object.keys(CollectionTypes).map(key =>
            <option
              key={`itemType-option-${key}`}
              value={CollectionTypes[key]}
            >
              {CollectionTypes[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      {/* PRIVATE */}

      <Form.Group className="mb-4">
        <Form.Check // prettier-ignore
          type='checkbox'
          checked={isPrivate}
          onClick={(event) => setIsPrivate(event.target.checked)}
          label='Private'
        />
      </Form.Group>

      {/* CHILDREN */}
      {/* COLLABORATORS */}

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={createCollectionHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Creating New Collection..." />
              :
              "Create New Collection"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
