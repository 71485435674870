import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { PlasticCodes, ItemStates } from '../../enums';

import BasicSpinner from '../common/basic-spinner';
import UpdateFormTags from '../common/update-form-tags';
import PlasticCodeChecks from '../common/plastic-code-checks';
import { Toaster, createToast } from '../common/toasts';
import { useAuth } from "../../utils/auth-provider";

//================================================================

export default function ItemUpdateForm({item, onUpdate, onCancel}){

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const [name, setName] = useState(item.name);
  const [itemDescription, setItemDescription] = useState(item.item_description);
  const [envDescription, setEnvDescription] = useState(item.env_description);
  const [plasticCodes, setPlasticCodes] = useState(item.plastic_codes);
  const [itemState, setItemState] = useState(item.item_state);
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const updatePlasticCodes = useCallback((code) => {
    if (plasticCodes.includes(code)){
      setPlasticCodes(plasticCodes.filter(e => e !== code));
    } else {
      setPlasticCodes([...plasticCodes, code]);
    }
  }, [plasticCodes])

  const sendUpdateRequest = useCallback(async (data, itemId) => {
    if (Object.keys(data).length <= 0) return null;

    setWorking(true);
    const item = await fetch(`/api/item/${itemId}`, {
      method : 'PUT',
      body : JSON.stringify(data),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok) {
        onUpdate(result);
        return result
      }
      setToasts([...toasts, createToast(
        "Failed to update item",
        `${response.status} - ${response.json()}`
      )]);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to update item ${itemId}: ${error.message}`
      )]);
    });
    setWorking(false);
    return item;
  }, [onUpdate]);

  const submitHandler = useCallback(async () => {
    const data = {};

    if (name !== item.name){
      data['name'] = name;
    }

    if (itemDescription !== item.item_description){
      data['item_description'] = itemDescription;
    }

    if (envDescription !== item.env_description){
      data['env_description'] = envDescription;
    }

    if(plasticCodes !== item.plastic_codes){
     data['plastic_codes'] = plasticCodes;
    }

    if(itemState !== item.item_state){
     data['item_state'] = itemState;
    }

    if (Object.keys(data).length > 0){
      await sendUpdateRequest(data, item._id);
    }
    onCancel();
  }, [name, itemDescription, envDescription, plasticCodes, itemState, item, onCancel]);

  //----------------------------------------------------------------

  return(
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Edit the item name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item Description</Form.Label>
        <Form.Control
          as="textarea"
          value={itemDescription}
          placeholder="Enter description"
          onChange={(event) => setItemDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            Edit the item description.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Environment Description</Form.Label>
        <Form.Control
          as="textarea"
          value={envDescription}
          placeholder="Enter description"
          onChange={(event) => setEnvDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            Edit the description of where it was found.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Plastic Codes</Form.Label>
        <PlasticCodeChecks
          selected={plasticCodes}
          onChange={updatePlasticCodes}
          disabled={working}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item State</Form.Label>
        <Form.Select
          onChange={(event) => setItemState(event.target.value)}
          disabled={working}
          defaultValue={itemState}
        >
          {Object.keys(ItemStates).map(key =>
            <option
              key={`itemState-option-${key}`}
              value={ItemStates[key]}
            >
              {ItemStates[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <UpdateFormTags
        object={item}
        onSubmit={sendUpdateRequest}
        disabled={working}
      />

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={submitHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Saving Changes..." />
              :
              "Save Changes"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
