import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

// import ModalShell from '../common/modal-shell';
import ParentDropdown from '../common/parent-dropdown';
import RowShell from '../common/row-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';

import CollectionDetails from '../collection/collection-details';

//================================================================

export default function PublicCollectionPage(){

  //----------------------------------------------------------------
  //Hooks and Variables

  const params = useParams();

  const [parents, setParents] = useState(null);

  const [collection, setCollection] = useState(null);
  const [collections, setCollections] = useState([]);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [children, setChildren] = useState(null);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------
  // Fetching and setting Collections

  const fetchCollections = useCallback( async () => {
    await setCollections(await fetch("/api/public/collections")
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch collections: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------
  // Fetch Items

  const fetchItems = useCallback( async () => {
    setItems( await fetch("/api/public/items")
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch items: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------
  // Fetching Images

  const fetchImages = useCallback( async () => {
    await setImages( await fetch("/api/public/images")
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch images: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------
  // Select the current Collection by ID

  const setCurrentCollection = useCallback( async (collectionId) => {
    setLoading(true);
    if (!collectionId) return;
    const index = await collections.findIndex(candidate => candidate._id === collectionId);
    if (index >= 0){
      collections[index].index = index;
      setCollection(collections[index]);
    }
  },[collections]);

  useEffect(() => {
    setCurrentCollection(params.collectionId);
  }, [collections]);

  //----------------------------------------------------------------

  const fetchChildren = useCallback(async () => {
    if (!collection) return;
    await setChildren(await fetch(`/api/public/collection/${collection._id}/children`)
    .then((response) => {
      if (response.ok) return response.json();
    }).catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching children for collection ${collection._id}: ${error.message}`
      )]);
    }));
  }, [collection, toasts]);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async () => {
    if(!collection) return;
    setParents(await fetch(`/api/public/collection/${collection._id}/parents`)
      .then((response) => {
        if (response.ok) return response.json();
      })
    );
  });

  //----------------------------------------------------------------

  useEffect(() => {
    fetchChildren();
    fetchParents();
    setLoading(false);
  }, [collection]);

  useEffect(() => {
    fetchCollections();
    fetchItems();
    fetchImages();
  }, []);

  return(
    <>
      <PreviousNext
        objects={collections}
        currentObject={collection}
        onClick={setCurrentCollection}
        basePath={'/collection'}
      />

      <Row className="mb-2">
        <Col className="h4">Collection:</Col>
      </Row>

      {!loading ?
        <>
          {collection ?
            <CollectionDetails collection={collection}/>
            :
            <>Unable to load Collection</>
          }
        </>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <ParentDropdown options={parents} />

      <Row className="mb-2 align-children-center">
        <Col className="text-start">
          <Link to={'/collections/'}>
            <Button className="text-nowrap">
              Back to Collections
            </Button>
          </Link>
        </Col>
      </Row>

      <hr></hr>

      {!loading ?
        <>
          <Row className="mb-3 align-children-center">
            <Col className="h4 my-0 text-start">Children:</Col>
          </Row>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Collections:</Col>
          </Row>

          <div className="mb-3">
            {children && children['collections'].length > 0 ?
              <>
                {children['collections'].map(collection => <RowShell
                  key={`collection-row-${collection._id}`}
                  id={collection._id}
                  linkTo={`/collection/${collection._id}`}
                  linkText={collection.name}
                />)}
              </>
              :
              <></>
            }
          </div>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Items:</Col>
          </Row>

          <div className="mb-3">
            {children && children['items'].length > 0 ?
              <>
                {children['items'].map(item => <RowShell
                  key={`item-row-${item._id}`}
                  id={item._id}
                  linkTo={`/item/${item._id}`}
                  linkText={item.name}
                />)}
              </>
              :
              <></>
            }
          </div>

          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Images:</Col>
          </Row>

          <div className="mb-3">
            {children && children['images'].length > 0 ?
              <>
                {children['images'].map(image => <RowShell
                  key={`image-row-${image._id}`}
                  id={image._id}
                  linkTo={`/image/${image._id}`}
                  linkText={image.name}
                />)}
              </>
              :
              <></>
            }
          </div>
        </>

        :

        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>

      }

      <Toaster toasts={toasts} />
    </>
  );
}
