import { Link } from 'react-router-dom';
import { Card, Col, Row, Button, Spinner, ToggleButton } from 'react-bootstrap';
import { useState, useCallback } from 'react';

import RemoveButton from './remove-button'

//================================================================

export default function CardShell({ linkTo, imgSrc, id, title, text, onAction, icon, rounded }){
  
  return(
    <Col className="p-2">
      <Card className="w-100 h-100 m-auto">
        <Link
          key={`link-${id}`}
          to={linkTo}
        >
          {imgSrc ? <Card.Img variant="top" src={imgSrc} /> : <></>}
        </Link>
        <Card.Body>
          <Card.Title>
            <Link
              key={`link-${id}`}
              to={linkTo}
            >
              {title || 'Title'}
            </Link>
          </Card.Title>
          <Card.Text>
            {text}
          </Card.Text>
          { onAction !== undefined ?
            <Row className="mb-2">
              <Col>
                <RemoveButton
                  linkTo={linkTo}
                  key={`remove-button-card-${id}`}
                  linkText={title}
                  objectId={id}
                  id={`card-${id}`}
                  onAction={onAction}
                  icon={icon}
                  rounded={rounded}
                />
              </Col>
            </Row>
            :
            <></>
          }
        </Card.Body>
      </Card>
    </Col>
  );
}
