import { Link } from 'react-router-dom';
import { Button, Spinner, ToggleButton } from 'react-bootstrap';
import { useState, useCallback } from 'react';

//================================================================

export default function RemoveButton({objectId, id, onAction, icon, rounded}){

  const actionIcon = icon || "dash";
  const roundedAmount = rounded ? `rounded-${rounded}` : '';

  const [working, setWorking] = useState(false);
  const [enabled, setEnabled] = useState(false);

  //----------------------------------------------------------------

  const handleButtonClick = useCallback(async () => {
    setWorking(true);
    await onAction(objectId);
    setWorking(false);
  }, [onAction, objectId]);

  //----------------------------------------------------------------

  return(
    
    <>
      <ToggleButton
        disabled={onAction ? false : true}
        id={`removeToggle-${id}`}
        type="checkbox"
        variant={enabled ? "outline-danger" : "outline-secondary" }
        checked={enabled}
        value="1"
        onChange={(e) => setEnabled(e.currentTarget.checked)}
        className={`btn-sm ${roundedAmount}`}
      >
        {enabled ? "Cancel" : <i className={`bi bi-${actionIcon}`} /> }
      </ToggleButton>
      {' '}
      <Button
        className={`btn-sm ${roundedAmount}`}
        variant="danger"
        onClick={onAction ? handleButtonClick : () => setEnabled(false)}
        hidden={!enabled}
        disabled={!onAction || !enabled || working}
      >
        <span className="visually-hidden">Delete</span>
        {working ?
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
          />
          :
          <i className={`bi bi-${actionIcon}`} />
        }
      </Button>
    </>
  );
}
