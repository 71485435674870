import { Col, Row, Form, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useCallback } from 'react';

import BasicSpinner from '../common/basic-spinner';
import RowShell from '../common/row-shell';

//================================================================

export default function AddChildren({parent, childList, onAdd}) {

  //----------------------------------------------------------------
  // Hooks
  const [childrenToAdd, setChildrenToAdd] = useState([])
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------
  // Functions

  const updateChildrenToAdd = useCallback(async (checked, childId) => {
    setWorking(true);
      if (checked){
        setChildrenToAdd([...childrenToAdd, childId]);
      } else {
        setChildrenToAdd(childrenToAdd.filter(id => id !== childId))
      }
    setWorking(false);
  });

  //----------------------------------------------------------------

  return (

    <>
      { childList ?
        <>
          <div className="mb-3">
            {childList.map(child => 
              <RowShell
                key={`child-select-${child._id}`}
                onSelect={updateChildrenToAdd}
                linkText={child.name}
                id={child._id}
              />
            )}
          </div>
          <div className="text-center">
            <Button
              onClick={() => onAdd(childrenToAdd)}
            >
              Add Selected as Children
            </Button>
          </div>
        </>
        :
        <>No Children to Select</>
      }
    </>
  );
}
