import { useContext, createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

//================================================================

const AuthContext = createContext();

//----------------------------------------------------------------

export default function AuthProvider({ children }) {

  //grab locally stored user and access token data
  const [user, setUser] = useState(localStorage.getItem("plasticene_user") || null);
  const [token, setToken] = useState(localStorage.getItem("plasticene_token") || null);

  const navigate = useNavigate();

  //----------------------------------------------------------------

  // helper to clear local storage and in-memory values
  const clearStorage = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("plasticene_user");
    localStorage.removeItem("plasticene_token");
  }

  const fetchToken = async (username, password) => {
  
    // fastAPI authorization using the Oauth2PasswordRequestForm
    let formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    const authResult = await fetch('/api/token', {
      method: 'POST',
      body: formData
    })
    .then((response) => {
      if (response.ok) return response.json();
    })
    .catch((error) => console.error(error));
    
    if (!authResult){
      clearStorage();
      return null;
    }

    return authResult.access_token;
  }

  const fetchAuthorizedUser = async (access_token) => {
    if (!access_token) return null;

    const authorizedUser = await fetch('/api/me', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      }
    })
    .then((response) => {
      if (response.ok) return response.json();
    })
    .catch((error) => console.error(error));

    if (!authorizedUser){
      clearStorage();
      return null;
    }

    return authorizedUser;
  };

  const loginAction = async (username, password) => {
    const access_token = await fetchToken(username, password);

    if (!access_token) return false

    await setToken(access_token);
    localStorage.setItem("plasticene_token", access_token);

    const user = await fetchAuthorizedUser(access_token);

    if (!user) return false;

    await setUser(user);
    localStorage.setItem("plasticene_user", user);

    return true;
  };

  const logOut = () => {
    clearStorage();
    navigate("/login");
  };

  //----------------------------------------------------------------

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

//----------------------------------------------------------------

export const useAuth = () => {
  return useContext(AuthContext);
};
