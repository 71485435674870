import { useParams, Link } from "react-router-dom";

import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import CardShell from '../common/card-shell';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';
import { useAuth } from "../../utils/auth-provider";

import ImageCreateForm from '../item/item-create-form';


//================================================================

export default function ItemsPage(){

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const params = useParams();
  const [items, setItems] = useState(null);
  const [toasts, setToasts] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  //----------------------------------------------------------------

  const fetchItems = useCallback( async () => {
    const itemObjects = await fetch(`/api/item/`, {headers: headers})
    .then((response) => {
      if (response.ok) return response.json();
      return null;
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to fetch items: ${error.message}`
      )]);
    })

    for(var i = 0; i < itemObjects.length; i++){
      itemObjects[i].imageData = await fetchImageData(itemObjects[i]);
    }

    setItems(itemObjects);
  });

  useEffect(() => {
    fetchItems();
  }, []);

  //----------------------------------------------------------------

  const deleteItem = useCallback(async (itemId) => {
    await fetch(`/api/item/${itemId}`, {
      method: 'DELETE',
      headers: headers
    })
    .then((response) => {
      if(response.status === 204 || response.status === 404){
        const filter = items.filter(item => item._id !== itemId);
        setItems(filter);
      } else {
        setToasts([...toasts, createToast(
          "Failed to delete item",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While deleting item ${itemId}: ${error.message}`
      )]);
    });
  }, [items]);

  //----------------------------------------------------------------

  function getBase64(blob, onLoadCallback) {
    return new Promise(function(resolve, reject) {
      var reader = new FileReader();
      reader.onload = function() { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const fetchImageData = useCallback(async (item) => {

    if (!item || !item.images[0]) return;

    const data = await fetch(
      `/api/image/${item.images[0]}/file?width=250&height=250`,
      {headers: headers}
    )
    .then((response) => {
      if(response.ok) return response.blob();
      return null
    })
    .then(async (blob) => {
      const base64 = await getBase64(blob);
      return base64;
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While deleting item ${item._id}: ${error.message}`
      )]);
    });
    return data;
  }, [items]);

  //----------------------------------------------------------------

  const addItem = useCallback(async (item) => {
    item.imageData = await fetchImageData(item);
    setItems([...items, item]);
    setModalShow(false);
  }, [items]);

  //----------------------------------------------------------------

  return(
    <>

      {items ?
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 my-0 text-start">Items:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setModalShow(true)}
              >
                Add New Item
              </Button>
            </Col>
          </Row>

          {items.map(item => <RowShell
            key={`item-row-${item._id}`}
            id={item._id}
            linkTo={`/dashboard/item/${item._id}`}
            linkText={item.name}
            onAction={deleteItem}
            icon='trash'
            rounded='1'
          />)}

          <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4 mb-2 p-0">
            {items.map(item => <CardShell
              key={`item-card-${item._id}`}
              id={item._id}
              imgSrc={item.imageData}
              linkTo={`/dashboard/item/${item._id}`}
              title={item.name}
              onAction={deleteItem}
              icon='trash'
              rounded='1'
            />)}
          </Row>
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

      <ModalShell
        title="Add New Image"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addItem}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}
