import { useParams, Link } from "react-router-dom";
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import ParentDropdown from '../common/parent-dropdown';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';
import AddChildren from '../common/add-children';
import { useAuth } from "../../utils/auth-provider";

import ItemDetails from './item-details';
import ImageCreateForm from '../image/image-create-form';
import ItemUpdateForm from './item-update-form';

//================================================================

export default function ItemPage(){

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const params = useParams();

  const [parents, setParents] = useState(null);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [updateModalShow, setUpdateModalShow] = useState(false);

  const [itemImages, setItemImages] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [createImageModalShow, setCreateImageModalShow] = useState(false);
  const [addImageModalShow, setAddImageModalShow] = useState(false);

  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const fetchItems = useCallback(async () => {
    setItems(await fetch(`/api/item/`, {headers: headers})
    .then((response) => {
      if (response.ok) return response.json();
      setToasts([...toasts, createToast(
        "Failed to fetch items",
        `${response.status} - ${response.json()}`
      )]);
      return [];
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching items: ${error.message}`
      )]);
    }));
  }, [params.itemId, toasts]);

  useEffect(() => {
    fetchItems();
    fetchAllImages();
  }, []);

  //----------------------------------------------------------------

  const setCurrentItem = useCallback(async (itemId) => {
    const index = await items.findIndex((element) => element._id === itemId);
    if (index >= 0){
      items[index].index = index;
      setItem(items[index]);
    }
  }, [items]);

  useEffect(() => {
    setCurrentItem(params.itemId);
  }, [items]);

  //----------------------------------------------------------------

  const addImageToItem = useCallback(async (image) => {
    fetch(`/api/item/${params.itemId}`, {
      method: 'PUT',
      body: JSON.stringify({'add_images':[image._id]}),
      headers: headers
    })
    .then(async (response) => {
      if(response.ok){
        setItem(await response.json());
      } else {
        setToasts([...toasts, createToast(
          "Failed to add image to item",
          `${response.status}: ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding images to item ${params.itemId}: ${error.message}`
      )]);
    });
  });

  const addImagesToItem = useCallback(async (images) => {
    if (!item) return;

    await fetch(`/api/item/${item._id}`, {
      method: 'PUT',
      body: JSON.stringify({'add_images': images}),
      headers: headers
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok){
        setItem(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to add images to item",
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding images ${images} to item ${item._id}: ${error.message}`
      )]);
    });
  }, [item, toasts]);

  //----------------------------------------------------------------

  const removeImageFromItem = useCallback(async (imageId) => {

    await fetch(`/api/item/${params.itemId}`, {
      method: 'PUT',
      body: JSON.stringify({'remove_images' : [imageId]}),
      headers: headers
    })
    .then(async (response) => {
      if(response.ok){
        setItem(await response.json());
      } else {
        setToasts([...toasts, createToast(
          "Failed to remove image from item",
          `${response.status}: ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While removing images from item ${params.itemId}: ${error.message}`
      )]);
    });
  }, [itemImages]);

  //----------------------------------------------------------------

  const fetchItemImages = useCallback(async (item) => {
    if (!item) return;

    const {images} = await fetch(`/api/item/${item._id}/images`,{headers: headers})
      .then((response) => {
        if (response.ok) return response.json();
        return {images: []};
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch images for item ${item._id}: ${error.message}`
        )]);
      });

    setItemImages(images);
  },[item]);

  useEffect(() => {
    fetchParents();
    fetchItemImages(item);
  }, [item]);

  //----------------------------------------------------------------
  // Fetching and Images

  const fetchAllImages = useCallback( async () => {
    setAllImages( await fetch("/api/image/", {headers: headers})
      .then((response) => {
       if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch images: ${error.message}`
        )]);
      })
    );
  }, [toasts]);

  //----------------------------------------------------------------

  const fetchParents = useCallback(async () => {
    if(!item) return;
    setParents( await fetch(`/api/item/${item._id}/parents`, {headers: headers})
      .then((response) => {
        if (response.ok) return response.json();
        return null;
      })
    );
  });

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={items}
        currentObject={item}
        onClick={setCurrentItem}
        basePath={`/dashboard/item`}
      />

      <Row className="mb-2">
        <Col className="h4">Item:</Col>
      </Row>

      {item ?
        <ItemDetails item={item}/>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

       <ParentDropdown options={parents} baseURL="/dashboard"/>

      <Row className="mb-2 align-items-center">
        <Col className="text-start">
          <Link to={`/dashboard/items/`}>
            <Button className="text-nowrap">
              Back to Items
            </Button>
          </Link>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => setUpdateModalShow(true)}
            disabled={!item}
          >
            Edit
          </Button>
        </Col>
      </Row>

      <hr></hr>

      {itemImages ?
        <>
          <Row className="mb-3 align-children-center">
            <Col className="h5 m-auto text-start col">Images:</Col>
            <Col className=" my-auto text-end col-2">Add</Col>
            <ButtonGroup className="col-md-3 text-center">
              <Button
                onClick={() => setCreateImageModalShow(true)}
              >
                New
              </Button>
              <Button
                onClick={() => setAddImageModalShow(true)}
              >
                Existing
              </Button>
            </ButtonGroup>
          </Row>

          {itemImages.map(image => <RowShell
            key={`image-link-${image._id}`}
            id={image._id}
            linkTo={`/dashboard/image/${image._id}`}
            linkText={image.name}
            onAction={removeImageFromItem}
            icon='dash'
            rounded='1'
          />)}
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

      <ModalShell
        title="Add New Image"
        show={createImageModalShow}
        onHide={() => setCreateImageModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setCreateImageModalShow(false)}
          onCreate={(image) => {
            addImageToItem(image);
            setCreateImageModalShow(false)
          }}
        />
      </ModalShell>

      <ModalShell
        key="addImageModal"
        title="Add Existing Images(s)"
        show={addImageModalShow}
        onHide={() => setAddImageModalShow(false)}
        hideFooter={true}
      >
        <AddChildren
          parent={item}
          childList={allImages && item ? allImages.filter(child => !item.images.includes(child._id)) : []}
          onAdd={(imageList) => {
            addImagesToItem(imageList);
            setAddImageModalShow(false);
          }}
        />
      </ModalShell>

      <ModalShell
        title="Update Item"
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        hideFooter={true}
      >
        <ItemUpdateForm
          item={item}
          onCancel={() => setUpdateModalShow(false)}
          onUpdate={fetchItems}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}
