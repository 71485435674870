import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import CardShell from '../common/card-shell';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';
import { useAuth } from "../../utils/auth-provider";

import ImageCreateForm from '../image/image-create-form';

//================================================================

export default function ImagesPage(){

  const user = useAuth();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${user.token}`
  }

  const [images, setImages] = useState([]);
  const [toasts, setToasts] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  //----------------------------------------------------------------

  const fetchImages = useCallback( async () => {
    const imageObjects = await fetch(`/api/image/`, {headers: headers})
    .then((response) => {
      if (response.ok) return response.json();
      return null;
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to fetch images: ${error.message}`
      )]);
    })

    for(var i = 0; i < imageObjects.length; i++){
      imageObjects[i].imageData = await fetchImageData(imageObjects[i]);
    }

    setImages(imageObjects);
  });

  useEffect(() => {
    fetchImages();
  }, []);

  //----------------------------------------------------------------

  const deleteImage = useCallback(async (imageId) => {
    await fetch(`/api/image/${imageId}`, {
      method: 'DELETE',
      headers: headers
    })
    .then((response) => {
      if(response.status === 204 || response.status === 404){
        const filter = images.filter(image => image._id !== imageId);
        setImages(filter);
      } else {
        setToasts([...toasts, createToast(
          "Failed to delete image",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While deleting image ${imageId}: ${error.message}`
      )]);
    });
  }, [images]);

  //----------------------------------------------------------------

  function getBase64(blob, onLoadCallback) {
    return new Promise(function(resolve, reject) {
      var reader = new FileReader();
      reader.onload = function() { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const fetchImageData = useCallback(async (image) => {

    if (!image) return;

    const data = await fetch(
      `/api/image/${image._id}/file?width=250&height=250`,
      {headers: headers}
    )
    .then((response) => {
      if(response.ok) return response.blob();
      return null
    })
    .then(async (blob) => {
      const base64 = await getBase64(blob);
      return base64;
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While retriving data for image ${image._id}: ${error.message}`
      )]);
    });
    return data;
  }, [images]);

  //----------------------------------------------------------------

  const addImage = useCallback(async (image) => {
    image.imageData = await fetchImageData(image);
    setImages([...images, image]);
    setModalShow(false);
  }, [images]);

  //----------------------------------------------------------------

  return(
    <>

      {images ?
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 my-0 text-start">Images:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setModalShow(true)}
              >
                Add New Image
              </Button>
            </Col>
          </Row>

          {images.map(image => <RowShell
            key={`image-row-${image._id}`}
            id={image._id}
            linkTo={`/dashboard/image/${image._id}`}
            linkText={image.name}
            onAction={deleteImage}
            icon='trash'
            rounded='1'
          />)}

          <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4 mb-2 p-0">
            {images.map(image => <CardShell
              key={`image-card-${image._id}`}
              id={image._id}
              imgSrc={image.imageData}
              linkTo={`/dashboard/image/${image._id}`}
              title={image.name}
              onAction={deleteImage}
              icon='trash'
              rounded='1'
            />)}
          </Row>
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner />
          </Col>
        </Row>
      }

      <ModalShell
        title="Add New Image"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addImage}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}
